@import '../constants/_colors.scss';

.row_style_calendar {
  z-index: 100000;
  padding: 10px;
  // border: 0;
  // border: 1px solid red;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 20px;
  color: color(text, 1);
  .react-calendar__navigation {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    button[disabled] {
      background-color: white;
      cursor: not-allowed;
      &:hover {
        background-color: #f8f7fa;
      }
    }
  }
  .react-calendar__navigation__arrow {
    border-radius: 47px;
  }
  .react-calendar__navigation__label {
    font-weight: 600;
    font-size: 14px;
    color: #19181a;
    background-color: white !important;
    &:focus {
      color: color(brand, primary);
    }
  }
  .react-calendar__month-view__weekdays {
    .react-calendar__month-view__weekdays__weekday {
      abbr[title] {
        font-size: 14px;
        color: #19181a;
        text-decoration: none;
        font-weight: 600;
        text-transform: capitalize;
        cursor: pointer;
      }
    }
  }
  .react-calendar__tile {
    padding: 0;
    height: 47px;
    width: 47px;
    border-radius: 47px;
    font-size: 14px;
    &:disabled {
      background-color: unset;
      cursor: not-allowed;
      &:hover {
        background-color: #f8f7fa;
      }
    }
  }
  .react-calendar__tile--now {
    background-color: #f8f7fa;
    color: color(brand, primary);
  }
  .react-calendar__tile--hasActive,
  .react-calendar__tile--active,
  .react-calendar__tile--hasActive:enabled,
  .react-calendar__tile--active:enabled {
    background: color(brand, primary) !important;
    color: white !important;
    &:hover,
    &:focus {
      opacity: 0.9;
    }
  }
}
