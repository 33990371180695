@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500&display=swap");

:root {
  --bodybg: #dcdefe;
  --primary-color: #2d2d2d;
  --grey: #d6d6d6;
  --placeholder: #969696;
  --white: rgba(255, 255, 255, 0.9);
  --text: #333;
  --slider-bg: #eff3ff;
  --login-cta-hover: #000000;
}

* {
  margin: 0;
  padding: 0;
}

.hs-login-page-container {
  background-image: url(../../images/login_bg.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Raleway", sans-serif;
  height: 100vh;
  display: flex;
}

.login-container {
  display: flex;
  /* max-width: 1200px; */
  background: var(--white);

  background: rgba(255, 255, 255, 0.9);
  margin: auto;
  /* width: 100%; */
  min-width: 320px;
}

.login-container .logo svg {
  height: 40px;
  width: 40px;
  fill: var(--primary-color);
}

.login-container .login-form {
  width: 500px;
  box-sizing: border-box;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.login-container .login-form .login-form-inner {
  max-width: 380px;
  width: 95%;
}

.login-container .login-form .google-login-button .google-icon svg {
  height: 20px;
  display: flex;
  margin-right: 10px;
}

.login-container .login-form .google-login-button {
  color: var(--text);
  border: 1px solid var(--grey);
  margin: 40px 0 20px;
}

.login-container .login-form .sign-in-seperator {
  text-align: center;
  color: var(--placeholder);
  position: relative;
  margin: 30px 0 20px;
}

.login-container .login-form .sign-in-seperator span {
  background: var(--white);
  z-index: 1;
  position: relative;
  padding: 0 10px;
  font-size: 14px;
}

.login-container .login-form .sign-in-seperator:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: var(--grey);
  left: 0;
  top: 50%;
  z-index: 0;
}

.login-container .login-form .login-form-group {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.login-container .login-form .login-form-group label {
  font-size: 14px;
  font-weight: 500;
  color: var(--text);
  margin-bottom: 10px;
}

.login-container .login-form .login-form-group input {
  padding: 13px 20px;
  box-sizing: border-box;
  border: 1px solid var(--grey);
  border-radius: 50px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: var(--text);
  transition: linear 0.2s;
}

.login-container .login-form .login-form-group input:focus {
  outline: none;
  border: 1px solid var(--primary-color);
}

.login-container
  .login-form
  .login-form-group
  input::-webkit-input-placeholder {
  color: var(--placeholder);
  font-weight: 300;
  font-size: 14px;
}

.login-container .login-form .login-form-group.single-row {
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 5px;
}

/* custom checkbox */
.login-container .login-form .custom-check input[type="checkbox"] {
  height: 23px;
  width: 23px;
  margin: 0;
  padding: 0;
  opacity: 1;
  appearance: none;
  border: 2px solid var(--primary-color);
  border-radius: 3px;
  background: var(--white);
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}

.login-container .login-form .custom-check input[type="checkbox"]:checked {
  border: 2px solid var(--primary-color);
  background: var(--primary-color);
}

.login-container
  .login-form
  .custom-check
  input[type="checkbox"]:checked:before,
.login-container
  .login-form
  .custom-check
  input[type="checkbox"]:checked:after {
  content: "";
  position: absolute;
  height: 2px;
  background: var(--white);
}

.login-container
  .login-form
  .custom-check
  input[type="checkbox"]:checked:before {
  width: 8px;
  top: 11px;
  left: 2px;
  transform: rotate(44deg);
}

.login-container
  .login-form
  .custom-check
  input[type="checkbox"]:checked:after {
  width: 14px;
  top: 8px;
  left: 5px;
  transform: rotate(-55deg);
}

.login-container .login-form .custom-check input[type="checkbox"]:focus {
  outline: none;
}

.login-container .login-form .custom-check {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container .login-form .custom-check label {
  margin: 0;
  color: var(--text);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.login-container .login-form .link {
  color: var(--primary-color);
  font-weight: 700;
  text-decoration: none;
  font-size: 14px;
}

.login-container .login-form .link:hover {
  text-decoration: underline;
}

.login-container .login-form .login-cta {
  color: var(--white);
  text-decoration: none;
  border: 1px solid var(--primary-color);
  margin: 25px 0 35px;
  background: var(--primary-color);
}

.login-container .login-form .login-cta:hover {
  background: var(--login-cta-hover);
}

.login-container .onboarding {
  flex: 1;
  background: var(--slider-bg);
  display: none;
  width: 50%;
}

.login-container .login-form .login-form-group label .required-star {
  color: var(--primary-color);
  font-size: 18px;
  line-height: 10px;
}

.login-container .rounded-button {
  display: flex;
  width: 100%;
  text-decoration: none;
  border-radius: 50px;
  padding: 13px 20px;
  box-sizing: border-box;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  transition: linear 0.2s;
}

.login-container .rounded-button:hover {
  box-shadow: 0px 0px 4px 0px var(--grey);
}

.login-container .body-text {
  font-size: 14px;
  font-weight: 500;
  color: var(--text);
}


.login-container .login-form .login-form-inner h1 {
  margin-bottom: 20px;
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .login-container .onboarding {
    display: flex;
  }
}

@media screen and (max-width: 767px) {
  .login-container {
    height: 100vh;
  }
}

@media screen and (width: 768px) {
  .login-container .onboarding {
    order: 0;
  }
  .login-container .login-form {
    order: 1;
  }
  .login-container {
    height: 100vh;
  }
}

@media screen and (max-width: 420px) {
  .login-container .login-form {
    padding: 20px;
  }
  .login-container .login-form-group {
    margin-bottom: 16px;
  }
  .login-container {
    margin: 0;
  }
}
