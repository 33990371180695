.loading-icon-container {
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #ffffff;
  opacity: 1;

  div.card.spinner-container {
    min-height: 50px;
    margin-top: 30px;
    border: none;
    background: transparent;
    text-align: center;
  }

  .loading-title {
    padding-bottom: 25px;
  }

  .spinner-gray {
    width: 500px;
    height: auto;
    max-width: 100%;
  }
}

#preloader {
  background-color: #fff;
  padding: 0;
  margin: 0;
  height: 100%;
  position: fixed;
  z-index: 99999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#preloader .lds-ripple {
  display: inline-block;
  position: relative;
  width: 150px;
  height: 150px;
}
#preloader .lds-ripple div {
  position: absolute;
  border: 4px solid #2c6de9e6;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
#preloader .lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 70px;
    left: 70px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 140px;
    height: 140px;
    opacity: 0;
  }
}
