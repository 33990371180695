@import "bootstrap/scss/bootstrap.scss";
@import "styles/custom-bootstrap.scss";
@import "./styles/constants/base_constants";
@import url("./icons/font-awesome/css/all.min.css");
@import url("./styles/animate/animate.min.css");
@import '../node_modules/animate.css/animate.css';

@font-face {
  font-family: Poppins;
  font-weight: 400;
  src: url("./fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-weight: 500;
  src: url("./fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-weight: 600;
  src: url("./fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-weight: 300;
  src: url("./fonts/Roboto/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-weight: 400;
  src: url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-weight: 500;
  src: url("./fonts/Roboto/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-weight: 600;
  src: url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-weight: 800;
  src: url("./fonts/Roboto/Roboto-Black.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-weight: 400;
  font-style: italic;
  src: url("./fonts/Roboto/Roboto-Italic.ttf") format("truetype");
}

@font-face {
  font-family: Gotham;
  font-weight: 300;
  src: url("./fonts/Gotham/GothamThin.otf") format("truetype");
}

@font-face {
  font-family: Gotham;
  font-weight: 400;
  src: url("./fonts/Gotham/GothamLight.otf") format("truetype");
}

@font-face {
  font-family: Gotham;
  font-weight: 500;
  src: url("./fonts/Gotham/GothamSSm-Medium_Web.woff2") format("truetype");
}

@font-face {
  font-family: Gotham;
  font-weight: 600;
  src: url("./fonts/Gotham/GothamBold.otf") format("truetype");
}

@font-face {
  font-family: Gotham;
  font-weight: 800;
  src: url("./fonts/Gotham/GothamBlack.otf") format("truetype");
}

@font-face {
  font-family: Gotham;
  font-weight: 400;
  font-style: italic;
  src: url("./fonts/Gotham/GothamBookItalic.otf") format("truetype");
}

@font-face {
  font-family: Playfair;
  src: url("./fonts/Playfair/PlayfairDisplay-VariableFont_wght.woff2")
    format("truetype");
}

@font-face {
  font-family: Playfair;
  font-style: italic;
  src: url("./fonts/Playfair/PlayfairDisplay-Italic-VariableFont_wght.ttf")
    format("truetype");
}

@font-face {
  font-family: SourceSansPro;
  font-weight: 300;
  src: url("./fonts/SourceSansPro/SourceSansPro-Light.ttf");
}

@font-face {
  font-family: SourceSansPro;
  font-weight: 400;
  src: url("./fonts/SourceSansPro/SourceSansPro-Regular.ttf");
}

@font-face {
  font-family: SourceSansPro;
  font-style: italic;
  src: url("./fonts/SourceSansPro/SourceSansPro-Italic.ttf");
}

@font-face {
  font-family: SourceSansPro;
  font-weight: 500;
  src: url("./fonts/SourceSansPro/SourceSansPro-Semibold.ttf");
}

@font-face {
  font-family: SourceSansPro;
  font-weight: 600;
  src: url("./fonts/SourceSansPro/SourceSansPro-Bold.ttf");
}

@font-face {
  font-family: SourceSansPro;
  font-weight: 800;
  src: url("./fonts/SourceSansPro/SourceSansPro-Black.ttf");
}

@font-face {
  font-family: GreatVibes;
  font-weight: 400;
  src: url("./fonts/GreatVibes/GreatVibes-Regular.ttf");
}

@font-face {
  font-family: Lora;
  font-weight: 400;
  src: url("./fonts/Lora/Lora-VariableFont_wght.ttf");
}

@font-face {
  font-family: DavibLibre;
  font-weight: 400;
  src: url("./fonts/DavibLibre/DavidLibre-Regular.ttf");
}

@font-face {
  font-family: DavibLibre;
  font-weight: 600;
  src: url("./fonts/DavibLibre/DavidLibre-Medium.ttf");
}

@font-face {
  font-family: DavibLibre;
  font-weight: 800;
  src: url("./fonts/DavibLibre/DavidLibre-Bold.ttf");
}

@font-face {
  font-family: Woodmart;
  font-weight: 400;
  src: url("./fonts/Woodmart/woodmart-font-1-400.woff2");
}

@font-face {
  font-family: "PreferredFont";
  src: local("Helvetica Neue");
}

.davibLibre-font {
  font-family: DavibLibre, PreferredFont, Helvetica, Arial, sans-serif,
    ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.gotham-font {
  font-family: Gotham, PreferredFont, Helvetica, Arial, sans-serif,
    ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.playfair-font {
  font-family: Playfair, PreferredFont, Helvetica, Arial, sans-serif,
    ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.sourceSansPro-font {
  font-family: SourceSansPro, PreferredFont, Helvetica, Arial, sans-serif,
    ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.greatVibes-font {
  font-family: GreatVibes, PreferredFont, Helvetica, Arial, sans-serif,
    ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.lora-font {
  font-family: Lora, PreferredFont, Helvetica, Arial, sans-serif, ui-sans-serif,
    system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.davibLibre-font {
  font-family: DavibLibre, PreferredFont, Helvetica, Arial, sans-serif,
    ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.woodmart-font {
  font-family: Woodmart, PreferredFont, Helvetica, Arial, sans-serif,
    ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

body {
  margin: 0;
  font-family: Poppins, PreferredFont, Helvetica, Arial, sans-serif,
    ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@mixin safeAreaBottom($baseBottom: 0rem) {
  bottom: calc(#{$baseBottom} + constant(safe-area-inset-bottom)) !important;
  bottom: calc(#{$baseBottom} + env(safe-area-inset-bottom)) !important;
}

.btn_safe_bottom {
  @include safeAreaBottom(30px);
}

.hs-withdraw-container {
  .form-control:focus {
    // border: none !important;
    // border-bottom: 1px solid color(line, 1) !important;
    // box-shadow: none !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  // input::-webkit-input-placeholder {
  //   color: color(text, 2);
  //   font-size: 12px;
  // }

  .hs-withdraw-amount-input::-webkit-input-placeholder {
    font-size: 16px;
  }

  .hs-withdraw-tip {
    span {
      font-weight: 500;

      a {
        color: color(cyan, 70) !important;
      }
    }
  }
}

.hs-users-table-container,
.hs-wallet-table-container,
.hs-order-table-container {
  margin-bottom: 0;
  thead th,
  tbody td {
    text-align: center;
    vertical-align: middle;
    height: 70px;
  }

  svg {
    display: inline-block;
  }
}

.hs-userlist-filter-container {
  input::-webkit-input-placeholder {
    color: color(text, 2);
    font-size: 14px;
  }
}

.hs-edit-control {
  > div:hover {
    border-color: color(brand, primary) !important;
    box-shadow: 0 0 0 1px color(brand, primary);
  }
}

input:disabled {
  cursor: not-allowed;
}

.custom-input {
  &::placeholder {
    color: #000000;
  }
  &:focus {
    box-shadow: none !important;
    border-color: color(brand, primary) !important;
  }
}

.entrance-banner {
  background-image: linear-gradient(to bottom, #2c00f2, #7902d2);
}

.hs-open-menu {
  color: color(brand, secondary);
  background-color: color(primary, 1);
  // border-bottom-right-radius: 50px;
  // border-top-right-radius: 5px;
  transition: all 0.5s;

  > i {
    width: 6px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: color(brand, secondary);
    transition: all 0.5s;
  }

  .arrow-icon {
    transition: all 0.5s;
    transform: rotate(90deg);
    path {
      fill: color(brand, secondary);
    }
  }
}

.hs-user-avatar {
  background: linear-gradient(to right, #cea7ff, #ffa7d7);
  ::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -0.1875rem;
    border-radius: inherit;
    background: linear-gradient(to right, #cea7ff, #ffa7d7);
  }
}

.scrollbar-hidden {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}

.dashboard-onboarding-img {
  // background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.52) 100%);
  background: color(brand, primary);
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.52) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.52) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#85ffffff',GradientType=0 );
    border-radius: 20px;
  }

  @keyframes movedelement {
    0% {
      -webkit-transform: translate(0);
      transform: translate(0);
    }
    25% {
      -webkit-transform: translate(10px, 30px);
      transform: translate(10px, 30px);
    }
    50% {
      -webkit-transform: translate(30px, 60px);
      transform: translate(30px, 60px);
    }
    75% {
      -webkit-transform: translate(10px, 90px);
      transform: translate(10px, 90px);
    }
    to {
      -webkit-transform: translate(0);
      transform: translate(0);
    }
  }

  .onboarding_img {
    animation: movedelement 10s linear infinite;
  }
}

.total-amount-dashboard {
  border: 1px solid red;
  div {
    width: 100% !important;
    height: 100% !important;
    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.dashboard-amount-statistics {
  position: relative;
  &::after {
    position: absolute;
    content: "";
    height: 120px;
    width: 120px;
    transform: rotate(40deg);

    border-radius: 1.75rem;
    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 0.15) 0,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0.15) 0,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.15) 0,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &:nth-child(1) {
    position: relative;
    &::after {
      top: -35px;
      right: -35px;
    }
  }

  &:nth-child(2) {
    position: relative;
    &::after {
      bottom: -40px;
      right: -30px;
    }
  }

  &:nth-child(3) {
    position: relative;
    &::after {
      top: -35px;
      right: 30px;
    }
  }

  &:nth-child(4) {
    position: relative;
    &::after {
      bottom: -45px;
      right: 0;
    }
  }
}

.animate-active-tab {
  animation: bounceIn 0.5s linear;
}

.failed-order-table {
  thead {
    th {
      text-align: center;
    }
  }
  tbody {
    td {
      padding: 20px 0;
      text-align: center;
      color: color(gray, 50);
    }
  }
}

.hs-role-selector-container {
  &.disabled {
    .css-1fhf3k1-control {
      cursor: not-allowed !important;
    }
    
  }
  .css-yk16xz-control {
    border: none !important;
    &:hover {
      border: none !important;
      outline: none !important;
    }
  }

  .css-1pahdxg-control {
    border: none !important;
    box-shadow: none !important;
    &:hover {
      border: none !important;
    }
  }

  .css-1fhf3k1-control {
    border-radius: 30px;
    background-color: #e9ecef !important;
    
  }
}

.hs-common-selector-container {
  .css-yk16xz-control {
    height: 43px;
    border: none !important;
    &:hover {
      border: none !important;
      outline: none !important;
    }

    .css-1wa3eu0-placeholder {
      font-size: 12px;
    }
  }

  .css-1pahdxg-control {
    height: 43px;
    border: none !important;
    box-shadow: none !important;
    &:hover {
      border: none !important;
    }
  }
}

.hs-Entrancepage-profile {
  .dropdown-menu {
    border: none !important;
    box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.1);
  }
}

.hamburger {
  &:hover {
    .line {
      width: 22px;
      transition: all 0.3s ease-in-out 0s;
    }
  }
}

.hs-route-menu-container {
  &:hover {
    .route-menu {
      background-color: color(primary, 1) !important;
      color: color(primary, 9);

      > i {
        width: 6px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: color(brand, secondary);
        transition: all 0.5s;
      }
    }
    
    
  }
}

.collapse-menu {
  width: 100px !important;

  .route-menu {
    border-radius: 50%;
    width: 60px !important;
    height: 60px !important;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover {
      background-color: color(secondary, 1);
    }
    i {
      &::before {
        display: flex;
        justify-content: center;
        align-items: center;
        // width: 30px !important;
        // height: 30px !important;
        font-size: 22px;
      }
    }

    
  }

  .hs-open-menu {
    border-radius: 100% !important;
  }

  .navbar-collapse {
    display: none;
    div {
      padding-left: 0px !important;
    }
  }
}

.full-route {
  width: calc(100% - 100px) !important;
}

.hs-table-row {
  // border: 3px solid red;
  svg {
    flex-shrink: 0;
    max-width: 24px;
    max-height: 24px;
  }
  .hs-td-cell {
    // border: 1px solid green;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-break: break-all;
  }
}

.echarts-for-react {
  width: 100% !important;
  div:nth-child(1) {
    width: 100% !important;
    canvas {
      width: 100% !important;
    }
  }
}

.required::before {
  content: '* ';
  color: color(red, 30);
}

input.form-control {
  &::placeholder {
    color: #808080;
    font-size: 12px;
  }
}

.hs-calendar.disabled {
  background-color: #e9ecef !important;
  cursor: not-allowed;
}

.hs-payout-certificate.disabled {
  cursor: not-allowed !important;
}


.hs-dashboard-selector-container {
  .css-yk16xz-control {
    border: 1px solid color(grey, 3) !important;
    box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
  }
  .css-1uccc91-singleValue, .css-1wa3eu0-placeholder {
    font-size: 12px !important;
  }
  
  .css-26l3qy-menu {
    font-size: 12px !important;
  }
}

// <div class=" "><div class=" css-4ljt47-MenuList"><div class=" css-1n7v3ny-option" id="react-select-2-option-0" tabindex="-1">YongHu</div></div></div>